<!-- 选择团队弹窗 -->
<template>
  <div class="">
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="40%"
      @close="closeModal"
       :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <ax-table
        ref="tableBox"
        :columns="columns"
        :show-search="true"
        :searchForm="searchForm"
        :searchActions="searchActions"
        :showToolBar="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
          getCheckboxProps: getCheckboxProps,
        }"
        :dataSourceApi="dataSourceApis"
        :dataSourceParams="dataSourceParams"
         :scroll="{ y: '55vh', x: '80vw' }"
        @sure="sure"
      >
      </ax-table>
    </el-dialog>
  </div>
</template>

<script>
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "groupName",
    options: { placeholder: "请输入团队名称" },
    formItem: {},
    col: { span: 8 },
  },
];
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from "./api";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      api,
      dataSourceApis: api.volunteerGroupList,
      dataSourceParams: {
        auditStatus: "1",
        volunteerId: "",
        gridId: "",
      },
      visible: false,
      title: "选择团队",
      searchActions: [
        { name: "search", text: "查询" },
        { name: "reset", text: "重置" },
        { name: "sure", text: "确定" },
      ],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "团队名称",
            dataIndex: "groupName",
            ellipsis: true,
            width: 200,
          },
          {
            title: "团长姓名",
            dataIndex: "residentName",
            ellipsis: true,
            width: 180,
          },
          {
            title: "联系方式",
            dataIndex: "contactWay",
            ellipsis: true,
            width: 130,
          },
        ],
        true //去掉序号
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 60,
      }),
      selectedRowKeys: [],
      id: "",
      groupId: "", //团队id
      gridId: "", //网格id
      volunteerId: "", //志愿者id
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    volunteerId(news, olds) {
      console.log(news, "123");
      this.volunteerId = news;
      this.$nextTick(() => {
        this.$refs.tableBox.getDataSource();
      });
    },
  },
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      console.log(record, "123");
      this.visible = true;
      this.volunteerId = record.id;
      this.gridId = record.gridId;
      this.dataSourceParams.volunteerId = this.volunteerId;
      this.dataSourceParams.gridId = this.gridId;
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
      // console.log(selectedRowKeys, row);
      this.groupId = selectedRowKeys;
    },
    getCheckboxProps(record) {
      console.log(record.id, "121");
      return {
        props: {
          disabled: record.status === "1",
        },
      };
    },
    // 确定添加入该志愿者的团队
    async sure() {
      const array = [];
      this.selectedRowKeys.forEach((item, index) => {
        array.push({
          groupId: this.groupId[index],
          volunteerId: this.volunteerId,
          auditStatus: "1",
        });
      });
      const res = await api.addVolunteerGroupDetailList(array);
      console.log(res, "129");
      if (res.status === 200) {
        this.$message.success("所属团队加入成功");
        this.closeModal();
      }
    },
    closeModal() {
      this.volunteerId = "";
      this.gridId = "";
      this.visible = false;
      this.$emit("refsh");
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>